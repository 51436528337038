import React from "react";
import styled from "styled-components";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Loader from "./Components/Loader";
import Authenticated from "./Authenticated";
import TheGeeksLogo from './Assets/WhiteLogo.png';
import BASEURL from "./constants";
import { useDispatch } from 'react-redux'
import { setDomain, setId, setTab, TABS } from './reducer.slice'
import { searchCustomerByEmail, searchOrderById } from './reducer.thunk'
import isValid from "./emailAddressValidator";

const Image = styled.img`
  max-width: 100px;
`;

const Error = styled(Paper)`
  padding: 1em;
  margin: 2em;
  text-align: center;
`;

const App = () => {
    const [loading, setLoading] = React.useState(true);
    const [authenticated, setAuthenticated] = React.useState(false);
    const [error, setError] = React.useState(null);
    const dispatch = useDispatch();

    window.Gnatta.on("init", async () => {
        window.Gnatta.on("interaction_loaded", async ({ dynamicData }) => {
            window.Gnatta.user.get().then(async user => {
                await load(user.id);
                let dynamicDataOrder = dynamicData.filter(x => x.name === "Order Number")[0];
                let dynamicDataEmail = dynamicData.filter(x => x.name === "Email Address")[0];
                handleDynamicData(dynamicDataOrder, dynamicDataEmail);
            })
            .catch((err) => {
                // ignore
            });
        });
    });

    const load = async userId => {
        try {
            var referrer = new URL(document.referrer).hostname.split(".")[0];
            dispatch(setDomain(referrer));
            const result = await window.Gnatta.http.get(`${BASEURL}/setup/initialise?domain=${referrer}&user=${userId}`, { headers: { "Accept": "application/json", "X-Request-Domain": referrer } });
            const response = JSON.parse(result.body);
            dispatch(setId(response.id));
            setAuthenticated(true);
            setLoading(false);
        } catch (error) {
            const response = JSON.parse(error);
            setAuthenticated(false);
            setLoading(false);
            
            if(response.body && response.body.includes('"')) {
                setError(response.body.replaceAll('"', ''));
            } else {
                setError(response?.body);
            }
        }
    }

    const handleDynamicData = (dynamicDataOrder, dynamicDataEmail) => {
        var trimmedOrder = dynamicDataOrder?.value?.value?.trim()
        if (trimmedOrder) {
            dispatch(searchOrderById(trimmedOrder));
            dispatch(setTab(TABS.ORDERS));
            return;
        }

        var trimmedEmail = dynamicDataEmail?.value?.value?.trim()
        if (trimmedEmail && isValid(trimmedEmail)) {
            dispatch(searchCustomerByEmail(trimmedEmail));
            dispatch(setTab(TABS.CUSTOMERS));
            return;
        }
    }

    if (!authenticated || loading) return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: '100vh', backgroundColor: '#454851' }}
        >
            <Grid item xs={12}>
                <Image src={TheGeeksLogo} alt="logo" />
            </Grid>
            <Grid item style={{ maxWidth: "400px" }}>
                {loading ? <Loader /> : <Error elevation={24}>
                    {error || "Oops, something has gone wrong"}
                </Error>}
            </Grid>
        </Grid>
    );

    return <Authenticated />;
}

export default App;
