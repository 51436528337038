import { createAsyncThunk } from "@reduxjs/toolkit";
import BASEURL from "./constants";

export const searchCustomerByEmail = createAsyncThunk("root/customer/search/email", async (email, { rejectWithValue, getState  }) => {
    try {
        const { domain, id } = getState().root;
        const result = await window.Gnatta.http.get(`${BASEURL}/customer/${id}/search?email=${email}`, { headers: { "Accept": "application/json", "X-Request-Domain": domain }});
        const response = JSON.parse(result.body);

        return {
            response,
        };
    } catch (err) {
        const response = JSON.parse(err);
        return rejectWithValue(response.body);
    }
});

export const searchCustomerByName = createAsyncThunk("root/customer/search/name", async (names, { rejectWithValue, getState  }) => {
    try {
        const { domain, id } = getState().root;
        const result = await window.Gnatta.http.get(`${BASEURL}/customer/${id}/search?firstName=${names[0]}&lastName=${names.slice(1).join(" ")}`, { headers: { "Accept": "application/json", "X-Request-Domain": domain }});
        const response = JSON.parse(result.body);

        return {
            response,
        };
    } catch (err) {
        const response = JSON.parse(err);
        return rejectWithValue(response.body);
    }
});

export const searchCustomerById = createAsyncThunk("root/customer/search/id", async (customerId, { rejectWithValue, getState  }) => {
    try {
        const { domain, id } = getState().root;
        const result = await window.Gnatta.http.get(`${BASEURL}/customer/${id}/by-customer/${customerId}`, { headers: { "Accept": "application/json", "X-Request-Domain": domain }});
        const response = JSON.parse(result.body);

        return {
            response,
        };
    } catch (err) {
        const response = JSON.parse(err);
        return rejectWithValue(response.body);
    }
});

export const searchOrderByStatus = createAsyncThunk("root/order/search/status", async (status, { rejectWithValue, getState  }) => {
    try {
        const { domain, id } = getState().root;
        const result = await window.Gnatta.http.get(`${BASEURL}/order/${id}/search?status=${status}`, { headers: { "Accept": "application/json", "X-Request-Domain": domain }});
        const response = JSON.parse(result.body);

        return {
            response,
        };
    } catch (err) {
        const response = JSON.parse(err);
        return rejectWithValue(response.body);
    }
});

export const searchOrderByCustomer = createAsyncThunk("root/order/search/customer", async (customerId, { rejectWithValue, getState  }) => {
    try {
        const { domain, id } = getState().root;
        const result = await window.Gnatta.http.get(`${BASEURL}/order/${id}/by-customer/${customerId}`, { headers: { "Accept": "application/json", "X-Request-Domain": domain }});
        const response = JSON.parse(result.body);

        return {
            response,
        };
    } catch (err) {
        const response = JSON.parse(err);
        return rejectWithValue(response.body);
    }
});

export const searchOrderById = createAsyncThunk("root/order/search/id", async (orderId, { rejectWithValue, getState  }) => {
    try {
        const { domain, id } = getState().root;
        const result = await window.Gnatta.http.get(`${BASEURL}/order/${id}/by-order/${encodeURIComponent(orderId)}`, { headers: { "Accept": "application/json", "X-Request-Domain": domain }});
        const response = JSON.parse(result.body);

        return {
            response,
        };
    } catch (err) {
        const response = JSON.parse(err);
        return rejectWithValue(response.body);
    }
});

export const searchProductsByStatus = createAsyncThunk("root/product/search/status", async (status, { rejectWithValue, getState  }) => {
    try {
        const { domain, id } = getState().root;
        const result = await window.Gnatta.http.get(`${BASEURL}/product/${id}/search?status=${status}`, { headers: { "Accept": "application/json", "X-Request-Domain": domain }});
        const response = JSON.parse(result.body);

        return {
            response,
        };
    } catch (err) {
        const response = JSON.parse(err);
        return rejectWithValue(response.body);
    }
});

export const searchProductsByType = createAsyncThunk("root/product/search/type", async (type, { rejectWithValue, getState  }) => {
    try {
        const { domain, id } = getState().root;
        const result = await window.Gnatta.http.get(`${BASEURL}/product/${id}/search?type=${type}`, { headers: { "Accept": "application/json", "X-Request-Domain": domain }});
        const response = JSON.parse(result.body);

        return {
            response,
        };
    } catch (err) {
        const response = JSON.parse(err);
        return rejectWithValue(response.body);
    }
});

export const searchProductsByVendor = createAsyncThunk("root/product/search/vendor", async (vendor, { rejectWithValue, getState  }) => {
    try {
        const { domain, id } = getState().root;
        const result = await window.Gnatta.http.get(`${BASEURL}/product/${id}/search?type=${vendor}`, { headers: { "Accept": "application/json", "X-Request-Domain": domain }});
        const response = JSON.parse(result.body);

        return {
            response,
        };
    } catch (err) {
        const response = JSON.parse(err);
        return rejectWithValue(response.body);
    }
});

export const searchProductsByIds = createAsyncThunk("root/product/search/ids", async (ids, { rejectWithValue, getState  }) => {
    try {
        const { domain, id } = getState().root;
        const result = await window.Gnatta.http.get(`${BASEURL}/product/${id}/search?${ids.map(id => `ids=${id}&`)}`, { headers: { "Accept": "application/json", "X-Request-Domain": domain }});
        const response = JSON.parse(result.body);

        return {
            response,
        };
    } catch (err) {
        const response = JSON.parse(err);
        return rejectWithValue(response.body);
    }
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    searchCustomerByEmail,
    searchCustomerByName,
    searchCustomerById,
    searchOrderByStatus,
    searchOrderByCustomer,
    searchOrderById,
    searchProductsByStatus,
    searchProductsByType,
    searchProductsByVendor,
    searchProductsByIds,
};
