import React from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { searchOptions } from "./constants";

const SearchBy = ({ selected, setSelected }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    return (
        <>
            <List
                component="nav"
                aria-label="Searching by"
                sx={{ bgcolor: 'background.paper', py: 0 }}
            >
                <ListItem
                    button
                    id="search-button"
                    aria-haspopup="listbox"
                    aria-controls="search-menu"
                    aria-label="Searching by"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    sx={{ py: 0 }}
                >
                    <ListItemText
                        primary="Searching by"
                        primaryTypographyProps={{ fontWeight: 600, color: "primary.main" }}
                        secondary={selected.label}
                    />
                </ListItem>
            </List>
            <Menu
                id="search-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'search-button',
                    role: 'listbox',
                }}
                PaperProps={{  
                    style: {  
                      width: "100%",  
                    }
                }}
            >
                {searchOptions.map(option => (
                    <MenuItem
                        key={option.value}
                        selected={selected.value === option.value}
                        onClick={(_) => { setSelected(option.value); setAnchorEl(null); }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default SearchBy;