export const STATUS = "STATUS";
export const CUSTOMERID = "CUSTOMERID";
export const ORDERID = "ORDERID";

export const searchOptions = [
    {
      value: 'STATUS',
      label: 'Status',
    },
    {
      value: 'ORDERID',
      label: 'Order Id',
    },
    {
      value: 'CUSTOMERID',
      label: 'Customer Id',
    },
];

export const statusOptions = [
    {
      value: 'any',
      label: 'Any',
    },
    {
      value: 'open',
      label: 'Open',
    },
    {
      value: 'closed',
      label: 'Closed',
    },
    {
        value: 'cancelled',
        label: 'Cancelled',
    },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    searchOptions,
    statusOptions,
};