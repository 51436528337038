export const TYPE = "TYPE";
export const VENDOR = "VENDOR";
export const STATUS = "STATUS";

export const searchOptions = [
  {
    value: 'STATUS',
    label: 'Status',
  },
  {
    value: 'TYPE',
    label: 'Type',
  },
  {
    value: 'VENDOR',
    label: 'Vendor',
  },
];

export const statusOptions = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'archived',
    label: 'Archived',
  },
  {
      value: 'draft',
      label: 'Draft',
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    searchOptions,
    statusOptions,
    TYPE,
    VENDOR,
    STATUS,
};