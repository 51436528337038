import React from "react";
import styled from "styled-components";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Error = styled(Paper)`
  padding: 1em;
  margin: 1em;
  text-align: center;
`;

export const NotFound = () => (
    <Grid item style={{ maxWidth: "400px"}}>
        <Error elevation={0} sx={{ bgcolor: '#454851', color: "#fff" }}>
            No matches found
        </Error>
    </Grid>
);

export default NotFound;
