import "./styles.css";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    width: 100%;
    height: 25vh;
`;

const Spinner = () => (
    <Container>
        <div className="spinner"><div></div><div></div><div></div><div></div></div>
    </Container>
);

export default Spinner;
