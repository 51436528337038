import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

export const Product = ({ product, expand }) => {
    const [expanded, setExpanded] = React.useState(expand);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ maxWidth: 325, mt: "1rem", mx: "auto" }} variant="outlined">
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: 'primary.main' }} aria-label="person">
                        {product.title.substring(0, 1).toUpperCase()}
                    </Avatar>
                }
                action={
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? `${product.id}-product-menu` : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <MoreVertIcon />
                    </IconButton>
                }
                title={product.title}
                subheader={`${product.id} - ${new Date(product.createdAt).toLocaleString()}`}
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="subtitle1" gutterBottom>Vendor - {product.vendor}</Typography>
                    <Typography variant="subtitle1" gutterBottom>Status - {product.status}</Typography>
                    <Typography variant="subtitle1" gutterBottom>Type - {product.productType}</Typography>
                    {product.options.map((item, i) => (
                        <>
                            <Typography key={`${i}-product-option-${product.id}-subtitle`} variant="subtitle1" gutterBottom>{item.name}</Typography>
                            <Typography key={`${i}-product-option-${product.id}-caption`} variant="caption" display="block" gutterBottom>
                                Types - {item.values.join(", ")} <br/>
                            </Typography>
                        </>
                    ))}
                    {product.variants.map((item, i) => (
                        <>
                            <Typography key={`${i}-product-variant-${product.id}-subtitle`} variant="subtitle1" gutterBottom>{item.title}</Typography>
                            <Typography key={`${i}-product-variant-${product.id}-caption`} variant="caption" display="block" gutterBottom>
                                Barcode - {item.barcode} <br/>
                                Remaining Stock - {item.inventoryQuantity} <br/>
                                Inventory Policy - {item.inventoryPolicy} <br/>
                                Inventory Management - {item.inventoryManagement} <br/>
                                Price - {item.price} <br/>
                                Weight - {item.weight}{item.weightUnit} <br/>
                            </Typography>
                        </>
                    ))}
                </CardContent>
            </Collapse>
            <Menu
                anchorEl={anchorEl}
                id={`${product.id}-product-menu`}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleExpandClick}>
                    <ListItemIcon
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ListItemIcon>
                    {expanded ? "Minimise" : "Expand"}
                </MenuItem>
            </Menu>
        </Card>
    );
};

export default Product;
