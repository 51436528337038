import TheGeeksLogo from '../../Assets/TheGeeksPoweredBy.png';
import { styled } from '@mui/material/styles';

const Image = styled((props) => {
    const { ...other } = props;
    return <img {...other} alt="logo" />;
})(() => ({
    maxHeight: "25px",
}));

const Container = styled((props) => {
    const { ...other } = props;
    return <div {...other} />;
})(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    bottom: '0',
    left: '0',
    position: 'fixed',
    width: '398px',
}));

const LogoBanner = () => (
    <Container>
        <Image src={TheGeeksLogo} alt="logo" />  
    </Container>
);

export default LogoBanner;
