export const NAME = "NAME";
export const EMAIL = "EMAIL";

export const searchOptions = [
  {
    value: 'NAME',
    label: 'Name',
  },
  {
    value: 'EMAIL',
    label: 'Email',
  },
  {
    value: 'ID',
    label: 'Id',
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    searchOptions,
    NAME,
    EMAIL,
};