import React from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useSelector, useDispatch } from 'react-redux';
import { setTab, TABS } from '../../reducer.slice';

const options = [
    TABS.CUSTOMERS,
    TABS.PRODUCTS,
    TABS.ORDERS,
];

const Navigation = () => {
    const tab = useSelector((state) => state.root.tab);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    return (
        <>
            <List
                component="nav"
                aria-label="Searching status"
                sx={{ bgcolor: 'background.paper', py: 0 }}
            >
                <ListItem
                    button
                    id="nav-button"
                    aria-haspopup="listbox"
                    aria-controls="nav-menu"
                    aria-label="Looking for"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    sx={{ py: 0 }}
                >
                    <ListItemText
                        primary="Looking for"
                        primaryTypographyProps={{ fontWeight: 600, color: "primary.main" }}
                        secondary={tab} />
                </ListItem>
            </List>
            <Menu
                id="nav-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'nav-button',
                    role: 'listbox',
                }}
                PaperProps={{  
                    style: {  
                      width: "100%",  
                    }
                }}
            >
                {options.map(option => (
                    <MenuItem
                        key={option}
                        selected={tab === option}
                        onClick={(_) => { dispatch(setTab(option)); setAnchorEl(null); }}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default Navigation;