import { createSlice } from '@reduxjs/toolkit'
import { 
    searchCustomerByEmail,
    searchCustomerByName,
    searchCustomerById,
    searchOrderByStatus, 
    searchOrderById, 
    searchOrderByCustomer,
    searchProductsByStatus, 
    searchProductsByVendor, 
    searchProductsByType,
    searchProductsByIds,
} from "./reducer.thunk";

export const TABS = {
    CUSTOMERS: "Customers",
    PRODUCTS: "Products",
    ORDERS: "Orders",
};

export const rootSlice = createSlice({
  name: 'root',
  initialState: {
    domain: null,
    id: null,
    customers: null,
    products: null,
    orders: null,
    loading: false,
    error: "",
    tab: TABS.CUSTOMERS,
  },
  reducers: {
    setDomain: (state, action) => {
      state.domain = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    setValidationError: (state, action) => {
        state.error = action.payload;
        state.loading = false;
    },
    setTab: (state, action) => {
        state.tab = action.payload;
    },
  },
  extraReducers: {
    [searchCustomerByEmail.pending]: (state) => {
        state.loading = true;
        state.error = "";
    },
    [searchCustomerByEmail.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.customers = action.payload.response;
    },
    [searchCustomerByEmail.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    [searchCustomerByName.pending]: (state) => {
        state.loading = true;
        state.error = "";
    },
    [searchCustomerByName.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.customers = action.payload.response;
    },
    [searchCustomerByName.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    [searchCustomerById.pending]: (state) => {
        state.loading = true;
        state.error = "";
    },
    [searchCustomerById.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.customers = { customers: [action.payload.response] }
    },
    [searchCustomerById.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    [searchOrderByStatus.pending]: (state) => {
        state.loading = true;
        state.error = "";
    },
    [searchOrderByStatus.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.orders = action.payload.response;
    },
    [searchOrderByStatus.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    [searchOrderByCustomer.pending]: (state) => {
        state.loading = true;
        state.error = "";
    },
    [searchOrderByCustomer.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.orders = { orders: action.payload.response }
    },
    [searchOrderByCustomer.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    [searchOrderById.pending]: (state) => {
        state.loading = true;
        state.error = "";
    },
    [searchOrderById.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.orders = action.payload.response;
    },
    [searchOrderById.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    [searchProductsByStatus.pending]: (state) => {
        state.loading = true;
        state.error = "";
    },
    [searchProductsByStatus.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.products = action.payload.response;
    },
    [searchProductsByStatus.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    [searchProductsByType.pending]: (state) => {
        state.loading = true;
        state.error = "";
    },
    [searchProductsByType.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.products = action.payload.response;
    },
    [searchProductsByType.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    [searchProductsByVendor.pending]: (state) => {
        state.loading = true;
        state.error = "";
    },
    [searchProductsByVendor.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.products = action.payload.response;
    },
    [searchProductsByVendor.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    [searchProductsByIds.pending]: (state) => {
        state.loading = true;
        state.error = "";
    },
    [searchProductsByIds.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.products = action.payload.response;
    },
    [searchProductsByIds.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
  }
})

export const {
    setDomain,
    setId,
    setValidationError,
    setTab,
} = rootSlice.actions

export default rootSlice.reducer