import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useDispatch } from 'react-redux';
import { searchOrderByCustomer } from "../../reducer.thunk";
import { setTab, TABS } from "../../reducer.slice";

export const Customer = ({ customer, expand }) => {
    const [expanded, setExpanded] = React.useState(expand);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleSearch = customerId => {
        dispatch(searchOrderByCustomer(customerId));
        dispatch(setTab(TABS.ORDERS));
    };

    return (
        <Card sx={{ maxWidth: 325, mt: "1rem", mx: "auto" }} variant="outlined">
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: 'primary.main' }} aria-label="person">
                        {customer.firstName.substring(0, 1).toUpperCase()}
                        {customer.lastName.substring(0, 1).toUpperCase()}
                    </Avatar>
                }
                action={
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? `${customer.id}-customer-menu` : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <MoreVertIcon />
                    </IconButton>
                }
                title={`${customer.firstName} ${customer.lastName}`}
                subheader={customer.id}
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="subtitle1" gutterBottom>Contact:</Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                        {customer.email} <br/>
                        {customer.defaultAddress?.phone}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>Address:</Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                        {customer.defaultAddress.address1} <br/>
                        {customer.defaultAddress.address2} <br/>
                        {customer.defaultAddress.city} <br/>
                        {customer.defaultAddress.country} <br/>
                        {customer.defaultAddress.zip}
                    </Typography>
                </CardContent>
            </Collapse>
            <Menu
                anchorEl={anchorEl}
                id={`${customer.id}-customer-menu`}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => handleSearch(customer.id)}>
                    <ListItemIcon>
                        <SearchIcon fontSize="small" />
                    </ListItemIcon>
                    View orders
                </MenuItem>
                <MenuItem onClick={handleExpandClick}>
                    <ListItemIcon
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ListItemIcon>
                    {expanded ? "Minimise" : "Expand"}
                </MenuItem>
            </Menu>
        </Card>
    );
};

export default Customer;
