import React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Order from "../Order";
import NotFound from "../NotFound";
import Spinner from "../Spinner";
import { useSelector, useDispatch } from 'react-redux';
import { setValidationError } from '../../reducer.slice';
import { searchOrderByStatus, searchOrderById, searchOrderByCustomer } from "../../reducer.thunk";
import SearchBy from "./SearchBy";
import StatusBy from "./StatusBy";
import { searchOptions, STATUS, CUSTOMERID, ORDERID } from "./constants";

const Orders = () => {
    const[type, setType] = React.useState(searchOptions[0]);
    const[search, setSearch] = React.useState("");
    const result = useSelector((state) => state.root.orders);
    const loading = useSelector((state) => state.root.loading);
    const error = useSelector((state) => state.root.error);
    const dispatch = useDispatch();

    const handleSelectChange = value => {
        const selected = searchOptions.find(x => x.value === value);
        setType(selected);
        setSearch("");
        dispatch(setValidationError(""));
    };

    const query = async e => {
        e.preventDefault();
        if (!type.value || !search) {
            dispatch(setValidationError("Please enter a value"));
            return;
        }

        if (type.value === STATUS) {
            dispatch(searchOrderByStatus(search));
            return;
        }

        if (type.value === CUSTOMERID) {
            dispatch(searchOrderByCustomer(search));
            return;
        }

        dispatch(searchOrderById(search));
    }

    return (
        <>
            <Paper elevation={0} sx={{ pb: 0 }} square>
                <form onSubmit={query}>
                <SearchBy selected={type} setSelected={handleSelectChange} />
                {type && type.value === STATUS && <StatusBy selected={search} setSelected={setSearch} />}
                <Paper elevation={0} sx={{ px: "1rem", pt: 0 }} square>
                    {type && type.value !== STATUS && type.value !== ORDERID &&
                        <TextField
                            variant="outlined"
                            id="search"
                            size="small"
                            autoComplete="off"
                            type="number"
                            label="Enter a value here"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            error={!!error}
                            helperText={error}
                            fullWidth
                            sx={{ mt: "5px" }}
                        />
                    }
                    {type && type.value !== STATUS && type.value !== CUSTOMERID &&
                        <TextField
                            variant="outlined"
                            id="search"
                            size="small"
                            autoComplete="off"
                            label="Enter a value here"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            error={!!error}
                            helperText={error}
                            fullWidth
                            sx={{ mt: "5px" }}
                        />
                    }
                    <Button type="submit" sx={{ width: 1, mt: 1, fontWeight: 600 }} variant="contained" onClick={query} disabled={loading || !type}>Search</Button>
                </Paper>
                </form>
            </Paper>
            <Box sx={{ p: 1, mb: "25px" }}>
                {result != null ? result.orders.map((order, i) => (
                    <Order key={`${i}-order`} order={order} onSelect={() => {}} expand={i === 0} />
                )) : null}
                {result != null && result.orders.length === 0  ? <NotFound /> : null}
                {loading ? <Spinner /> : null}
            </Box>
        </>
    );
};

export default Orders;