import React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Customer from "../Customer";
import isValid from "../../emailAddressValidator";
import NotFound from "../NotFound";
import Spinner from "../Spinner";
import { useSelector, useDispatch } from 'react-redux';
import { setValidationError } from '../../reducer.slice';
import { searchCustomerByEmail, searchCustomerByName, searchCustomerById } from "../../reducer.thunk";
import SearchBy from "./SearchBy";
import { searchOptions, NAME, EMAIL } from "./constants";

const Customers = () => {
    const[type, setType] = React.useState(searchOptions[0]);
    const[search, setSearch] = React.useState("");
    const result = useSelector((state) => state.root.customers);
    const loading = useSelector((state) => state.root.loading);
    const error = useSelector((state) => state.root.error);
    const dispatch = useDispatch();

    const handleSelectChange = value => {
        setType(value);
        setSearch("");
        dispatch(setValidationError(""));
    };

    const query = async e => {
        e.preventDefault();

        if (!type.value || !search) {
            dispatch(setValidationError("Please enter a value"));
            return;
        }

        if (type.value === NAME) {
            const names = search.split(" ");
            if (names.length < 2) {
                dispatch(setValidationError("Please enter a first and last name"));
                return;
            }

            dispatch(searchCustomerByName(names));
            return;
        }

        if (type.value === EMAIL) {
            if(!isValid(search)) {
                dispatch(setValidationError("Please enter a valid email address"));
                return;
            }
            
            dispatch(searchCustomerByEmail(search));
            return;
        }

        dispatch(searchCustomerById(search));
    }

    return (
        <>
            <Paper elevation={0} sx={{ pb: 0 }} square>
                <form onSubmit={query}>
                <SearchBy selected={type} setSelected={handleSelectChange} />
                <Paper elevation={0} sx={{ px: "1rem", pt: 0 }} square>
                    {type &&
                        <TextField
                            variant="outlined"
                            size="small"
                            id="search"
                            autoComplete="off"
                            type={type.value === "ID" ? "number" : "text"}
                            label="Enter a value here"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            error={!!error}
                            helperText={error}
                            fullWidth
                            sx={{ mt: "5px" }}
                        />
                    }
                    <Button type="submit" sx={{ width: 1, mt: 1, fontWeight: 600 }} variant="outlined" onClick={query} disabled={loading || !type}>Search</Button>
                </Paper>
                </form>
            </Paper>
            <Box sx={{ p: 1, mb: "25px" }}>
                {result != null ? result.customers.map((customer, i) => (
                    <Customer key={`${i}-customer`} customer={customer} expand={i === 0} />
                )) : null}
                {result != null && result.customers.length === 0  ? <NotFound /> : null}
                {loading ? <Spinner /> : null}
            </Box>
        </>
    );
};

export default Customers;