import React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Product from "../Product";
import NotFound from "../NotFound";
import Spinner from "../Spinner";
import { useSelector, useDispatch } from 'react-redux';
import { setValidationError } from '../../reducer.slice';
import { searchProductsByStatus, searchProductsByVendor, searchProductsByType } from "../../reducer.thunk";
import SearchBy from "./SearchBy";
import StatusBy from "./StatusBy";
import { searchOptions, TYPE, VENDOR, STATUS } from "./constants";

const Products = () => {
    const[type, setType] = React.useState(searchOptions[0]);
    const[search, setSearch] = React.useState("");
    const result = useSelector((state) => state.root.products);
    const loading = useSelector((state) => state.root.loading);
    const error = useSelector((state) => state.root.error);
    const dispatch = useDispatch();

    const handleSelectChange = value => {
        setType(value);
        setSearch("");
        dispatch(setValidationError(""));
    };

    const query = async e => {
        e.preventDefault();

        if (!type.value || !search) {
            dispatch(setValidationError("Please enter a value"));
            return;
        }

        if (type.value === TYPE) {
            dispatch(searchProductsByType(search));
            return;
        }

        if (type.value === VENDOR) {
            dispatch(searchProductsByVendor(search));
            return;
        }

        dispatch(searchProductsByStatus(search));
    }

    return (
        <>
            <Paper elevation={0} sx={{ pb: 0 }} square>
                <form onSubmit={query}>
                <SearchBy selected={type} setSelected={handleSelectChange} />
                {type && type.value === STATUS && <StatusBy selected={search} setSelected={setSearch} />}
                <Paper elevation={0} sx={{ px: "1rem", pt: 0 }} square>
                    {type && type.value !== STATUS &&
                        <TextField
                            variant="outlined"
                            size="small"
                            id="search"
                            autoComplete="off"
                            type="text"
                            label="Enter a value here"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            error={!!error}
                            helperText={error}
                            fullWidth
                            sx={{ mt: "5px" }}
                        />
                    }
                    <Button type="submit" sx={{ width: 1, mt: 1, fontWeight: 600 }} variant="outlined" onClick={query} disabled={loading || !type}>Search</Button>
                </Paper>
                </form>
            </Paper>
            <Box sx={{ p: 1, mb: "25px" }}>
                {result != null ? result.products.map((product, i) => (
                    <Product key={`${i}-product`} product={product} expand={i === 0} />
                )) : null}
                {result != null && result.products.length === 0  ? <NotFound /> : null}
                {loading ? <Spinner /> : null}
            </Box>
        </>
    );
};

export default Products;