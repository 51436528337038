import React from "react";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Customers from "./Components/Customers";
import Products from "./Components/Products";
import Orders from "./Components/Orders";
import LogoBanner from "./Components/LogoBanner";
import Navigation from "./Components/Navigation";
import ShopifyPlaceholder from "./Components/ShopifyPlaceholder";
import { useSelector } from 'react-redux';
import { TABS } from './reducer.slice';

const Authenticated = () => {
    const tab = useSelector((state) => state.root.tab);

    return (
        <Box 
            sx={{ maxWidth: 398, mb: "2px" }}
            style={{ 
                borderTopRightRadius: 0, 
                borderTopLeftRadius: 0, 
                borderTop: "10px solid #619B8A"
            }}
        >
            <Paper elevation={0}>
                <ShopifyPlaceholder />
                <Navigation />
            </Paper>
            {tab === TABS.CUSTOMERS && <Customers />}
            {tab === TABS.PRODUCTS && <Products />}
            {tab === TABS.ORDERS && <Orders />}
            <LogoBanner />
        </Box>
    );
};

export default Authenticated;